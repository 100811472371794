<template>
  <svg
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    class="w-[10px] aspect-square md:w-[16px]"
  >
    <circle
      v-if="symbol === 1 || symbol === 3"
      cx="8"
      cy="8"
      r="5"
      stroke-width="2"
      :class="[symbol === 1 ? fillClass : 'fill-transparent', strokeClass]"
    />
    <polygon
      v-else
      points="8,2.5 13,11 3,11 8,2.5"
      stroke-width="2"
      :class="[symbol === 2 ? fillClass : 'fill-transparent', strokeClass]"
    />
  </svg>
</template>

<script setup lang="ts">
const props = defineProps<{
  symbol: number
  inverted?: boolean
}>()

const fillClass = computed(() =>
  props.inverted ? 'fill-white' : 'fill-gray-500',
)
const strokeClass = computed(() =>
  props.inverted ? 'stroke-white' : 'stroke-gray-500',
)
</script>

import { defineStore } from 'pinia'
import {
  GlobalConfigFragment,
  ShopPriceLogicFragment,
} from '#graphql-operations'

type PriceLogic = {
  small?: string
  large: string
}

interface State {
  /**
   * True if the global config has already been fetched.
   */
  fetched: boolean

  /**
   * The main contact phone number.
   */
  phoneContact: string

  /**
   * The AGB link.
   */
  termsAndConditionsLink: string

  /**
   * The insanely complex logic when and how to display which prices.
   */
  priceLogic: Record<string, PriceLogic>

  /**
   * The link to the "Mitgliedschaft" form page.
   */
  membershipFormLink: string

  /**
   * The link to the "Mitgliedermanagement" contact page.
   */
  memberManagementLink: string

  /**
   * The link to the form page used by users to add their GLN number.
   */
  missingGLNNumberFormLink: string

  /**
   * The link is shown on the Paywall banner if a non-affiliated pharmacy tries to access a product available only for affiliated pharmacies.
   */

  linkForNonAffiliatedPharmacies: string

  /**
   * The link is shown on the Paywall banner if a non member tries to access a product available only for individual members.
   */

  linkForNonMembers: string

  /**
   * The text for the cookie banner.
   */
  cookieBannerText: string
}

export const useGlobalConfig = defineStore({
  id: 'globalConfig',
  state: (): State => {
    return {
      fetched: false,
      phoneContact: '',
      priceLogic: {},
      memberManagementLink: '',
      termsAndConditionsLink: '',
      membershipFormLink: '',
      missingGLNNumberFormLink: '',
      linkForNonAffiliatedPharmacies: '',
      linkForNonMembers: '',
      cookieBannerText: '',
    }
  },
  actions: {
    setConfig(
      config: GlobalConfigFragment | undefined | null = {},
      shopPriceLogic: ShopPriceLogicFragment[],
    ) {
      this.fetched = true
      this.phoneContact = config?.phoneContact || ''
      this.memberManagementLink = config?.memberManagement?.uri?.path || ''
      this.termsAndConditionsLink =
        config?.termsAndConditionsLink?.url?.path || ''
      this.membershipFormLink = config?.membershipFormLink?.uri?.path || ''
      this.missingGLNNumberFormLink =
        config?.missingGLNNumberFormLink?.uri?.path || ''
      this.linkForNonAffiliatedPharmacies =
        config?.linkForNonAffiliatedPharmacies?.uri?.path || ''
      this.linkForNonMembers = config?.linkForNonMembers?.uri?.path || ''
      this.cookieBannerText = config?.cookieBannerText || ''

      const map: Record<string, PriceLogic> = {}

      shopPriceLogic?.forEach((v) => {
        if (v.key && v.large) {
          map[v.key] = {
            large: v.large,
            small: v.small,
          }
        }
      })

      this.priceLogic = map
    },
  },
})

import { default as indexkoivs8q9ZbMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/[...slug]/index.vue?macro=true";
import { default as indexfKa4cA2aWoMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/article/[...slug]/index.vue?macro=true";
import { default as indexMegK8NABVeMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/articles/index.vue?macro=true";
import { default as indexWV8eR38zLKMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/cart/index.vue?macro=true";
import { default as completefpy0SquUgUMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/checkout/[orderId]/complete.vue?macro=true";
import { default as loginVQAy4GZNzzMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/checkout/[orderId]/login.vue?macro=true";
import { default as indexk89KPbqs69Meta } from "/builds/bazinga/pharmasuisse/frontend/pages/event/[nid]/[...slug]/index.vue?macro=true";
import { default as indexuQREynsiCaMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/events/index.vue?macro=true";
import { default as _91id_93IWwYrHPVI3Meta } from "/builds/bazinga/pharmasuisse/frontend/pages/form/[id].vue?macro=true";
import { default as _91id_93XVGWHcUqiOMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/form/confirmation/[id].vue?macro=true";
import { default as indexm1semfiI8YMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/index.vue?macro=true";
import { default as islands_45pageHIB3DRLxLuMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/islands-page.vue?macro=true";
import { default as indexpzCPJ6kWkIMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/media/[id]/index.vue?macro=true";
import { default as indexYVga7PhUChMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/node/[nid]/index.vue?macro=true";
import { default as index9tPdmztHdEMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/press-release/[...slug]/index.vue?macro=true";
import { default as indexDpT5sHdjWyMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/press-releases/index.vue?macro=true";
import { default as indexlIvEVVgGTbMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/search/index.vue?macro=true";
import { default as _91_46_46_46slug_936K9nWRs1zmMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/shop/[id]/[...slug].vue?macro=true";
import { default as indexathjh4gz02Meta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/shop/index.vue?macro=true";
import { default as index7nwxEY0CRRMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/signup/account-exists/index.vue?macro=true";
import { default as indexdID2vVnt6vMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/signup/index.vue?macro=true";
import { default as indexlmNudOYy5QMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/signup/number-not-found/index.vue?macro=true";
import { default as indexKWJ6F2Na8ZMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/[id]/administration/index.vue?macro=true";
import { default as indexpJIf2s9ULVMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/[id]/contact/index.vue?macro=true";
import { default as indexz4HtAkrcUZMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/[id]/index.vue?macro=true";
import { default as indexxQt6J8t9CrMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/[id]/pharmacies-overview/index.vue?macro=true";
import { default as _91id_93PWmlmvL259Meta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/[id].vue?macro=true";
import { default as indextJzC35YUO5Meta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/activation/[uid]/[timestamp]/[hash]/login/index.vue?macro=true";
import { default as index8GptEvfL6qMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/login/index.vue?macro=true";
import { default as indexopIpOlRKgjMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/password/index.vue?macro=true";
import { default as component_45stub59CDIxQOB7Meta } from "/builds/bazinga/pharmasuisse/frontend/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stub59CDIxQOB7 } from "/builds/bazinga/pharmasuisse/frontend/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: indexkoivs8q9ZbMeta?.name ?? "slug",
    path: indexkoivs8q9ZbMeta?.path ?? "/:slug(.*)*",
    meta: indexkoivs8q9ZbMeta || {},
    alias: indexkoivs8q9ZbMeta?.alias || [],
    redirect: indexkoivs8q9ZbMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfKa4cA2aWoMeta?.name ?? "article-slug",
    path: indexfKa4cA2aWoMeta?.path ?? "/article/:slug(.*)*",
    meta: indexfKa4cA2aWoMeta || {},
    alias: indexfKa4cA2aWoMeta?.alias || [],
    redirect: indexfKa4cA2aWoMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/article/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMegK8NABVeMeta?.name ?? "articles",
    path: indexMegK8NABVeMeta?.path ?? "/articles",
    meta: indexMegK8NABVeMeta || {},
    alias: indexMegK8NABVeMeta?.alias || [],
    redirect: indexMegK8NABVeMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexWV8eR38zLKMeta?.name ?? "cart",
    path: indexWV8eR38zLKMeta?.path ?? "/cart",
    meta: indexWV8eR38zLKMeta || {},
    alias: indexWV8eR38zLKMeta?.alias || [],
    redirect: indexWV8eR38zLKMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: completefpy0SquUgUMeta?.name ?? "checkout-orderId-complete",
    path: completefpy0SquUgUMeta?.path ?? "/checkout/:orderId()/complete",
    meta: completefpy0SquUgUMeta || {},
    alias: completefpy0SquUgUMeta?.alias || [],
    redirect: completefpy0SquUgUMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/checkout/[orderId]/complete.vue").then(m => m.default || m)
  },
  {
    name: loginVQAy4GZNzzMeta?.name ?? "checkout-orderId-login",
    path: loginVQAy4GZNzzMeta?.path ?? "/checkout/:orderId()/login",
    meta: loginVQAy4GZNzzMeta || {},
    alias: loginVQAy4GZNzzMeta?.alias || [],
    redirect: loginVQAy4GZNzzMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/checkout/[orderId]/login.vue").then(m => m.default || m)
  },
  {
    name: indexk89KPbqs69Meta?.name ?? "event-nid-slug",
    path: indexk89KPbqs69Meta?.path ?? "/event/:nid()/:slug(.*)*",
    meta: indexk89KPbqs69Meta || {},
    alias: indexk89KPbqs69Meta?.alias || [],
    redirect: indexk89KPbqs69Meta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/event/[nid]/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexuQREynsiCaMeta?.name ?? "events",
    path: indexuQREynsiCaMeta?.path ?? "/events",
    meta: indexuQREynsiCaMeta || {},
    alias: indexuQREynsiCaMeta?.alias || [],
    redirect: indexuQREynsiCaMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93IWwYrHPVI3Meta?.name ?? "form-id",
    path: _91id_93IWwYrHPVI3Meta?.path ?? "/form/:id()",
    meta: _91id_93IWwYrHPVI3Meta || {},
    alias: _91id_93IWwYrHPVI3Meta?.alias || [],
    redirect: _91id_93IWwYrHPVI3Meta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/form/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93XVGWHcUqiOMeta?.name ?? "form-confirmation-id",
    path: _91id_93XVGWHcUqiOMeta?.path ?? "/form/confirmation/:id()",
    meta: _91id_93XVGWHcUqiOMeta || {},
    alias: _91id_93XVGWHcUqiOMeta?.alias || [],
    redirect: _91id_93XVGWHcUqiOMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/form/confirmation/[id].vue").then(m => m.default || m)
  },
  {
    name: indexm1semfiI8YMeta?.name ?? "index",
    path: indexm1semfiI8YMeta?.path ?? "/",
    meta: indexm1semfiI8YMeta || {},
    alias: indexm1semfiI8YMeta?.alias || [],
    redirect: indexm1semfiI8YMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/index.vue").then(m => m.default || m)
  },
  {
    name: islands_45pageHIB3DRLxLuMeta?.name ?? "islands-page",
    path: islands_45pageHIB3DRLxLuMeta?.path ?? "/islands-page",
    meta: islands_45pageHIB3DRLxLuMeta || {},
    alias: islands_45pageHIB3DRLxLuMeta?.alias || [],
    redirect: islands_45pageHIB3DRLxLuMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/islands-page.vue").then(m => m.default || m)
  },
  {
    name: indexpzCPJ6kWkIMeta?.name ?? "media-id",
    path: indexpzCPJ6kWkIMeta?.path ?? "/media/:id()",
    meta: indexpzCPJ6kWkIMeta || {},
    alias: indexpzCPJ6kWkIMeta?.alias || [],
    redirect: indexpzCPJ6kWkIMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/media/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexYVga7PhUChMeta?.name ?? "node-nid",
    path: indexYVga7PhUChMeta?.path ?? "/node/:nid()",
    meta: indexYVga7PhUChMeta || {},
    alias: indexYVga7PhUChMeta?.alias || [],
    redirect: indexYVga7PhUChMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/node/[nid]/index.vue").then(m => m.default || m)
  },
  {
    name: index9tPdmztHdEMeta?.name ?? "press-release-slug",
    path: index9tPdmztHdEMeta?.path ?? "/press-release/:slug(.*)*",
    meta: index9tPdmztHdEMeta || {},
    alias: index9tPdmztHdEMeta?.alias || [],
    redirect: index9tPdmztHdEMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/press-release/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexDpT5sHdjWyMeta?.name ?? "press-releases",
    path: indexDpT5sHdjWyMeta?.path ?? "/press-releases",
    meta: indexDpT5sHdjWyMeta || {},
    alias: indexDpT5sHdjWyMeta?.alias || [],
    redirect: indexDpT5sHdjWyMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/press-releases/index.vue").then(m => m.default || m)
  },
  {
    name: indexlIvEVVgGTbMeta?.name ?? "search",
    path: indexlIvEVVgGTbMeta?.path ?? "/search",
    meta: indexlIvEVVgGTbMeta || {},
    alias: indexlIvEVVgGTbMeta?.alias || [],
    redirect: indexlIvEVVgGTbMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_936K9nWRs1zmMeta?.name ?? "shop-id-slug",
    path: _91_46_46_46slug_936K9nWRs1zmMeta?.path ?? "/shop/:id()/:slug(.*)*",
    meta: _91_46_46_46slug_936K9nWRs1zmMeta || {},
    alias: _91_46_46_46slug_936K9nWRs1zmMeta?.alias || [],
    redirect: _91_46_46_46slug_936K9nWRs1zmMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/shop/[id]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexathjh4gz02Meta?.name ?? "shop",
    path: indexathjh4gz02Meta?.path ?? "/shop",
    meta: indexathjh4gz02Meta || {},
    alias: indexathjh4gz02Meta?.alias || [],
    redirect: indexathjh4gz02Meta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: index7nwxEY0CRRMeta?.name ?? "signup-account-exists",
    path: index7nwxEY0CRRMeta?.path ?? "/signup/account-exists",
    meta: index7nwxEY0CRRMeta || {},
    alias: index7nwxEY0CRRMeta?.alias || [],
    redirect: index7nwxEY0CRRMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/signup/account-exists/index.vue").then(m => m.default || m)
  },
  {
    name: indexdID2vVnt6vMeta?.name ?? "signup",
    path: indexdID2vVnt6vMeta?.path ?? "/signup",
    meta: indexdID2vVnt6vMeta || {},
    alias: indexdID2vVnt6vMeta?.alias || [],
    redirect: indexdID2vVnt6vMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: indexlmNudOYy5QMeta?.name ?? "signup-number-not-found",
    path: indexlmNudOYy5QMeta?.path ?? "/signup/number-not-found",
    meta: indexlmNudOYy5QMeta || {},
    alias: indexlmNudOYy5QMeta?.alias || [],
    redirect: indexlmNudOYy5QMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/signup/number-not-found/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93PWmlmvL259Meta?.name ?? undefined,
    path: _91id_93PWmlmvL259Meta?.path ?? "/user/:id()",
    meta: _91id_93PWmlmvL259Meta || {},
    alias: _91id_93PWmlmvL259Meta?.alias || [],
    redirect: _91id_93PWmlmvL259Meta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/[id].vue").then(m => m.default || m),
    children: [
  {
    name: indexKWJ6F2Na8ZMeta?.name ?? "user-id-administration",
    path: indexKWJ6F2Na8ZMeta?.path ?? "administration",
    meta: indexKWJ6F2Na8ZMeta || {},
    alias: indexKWJ6F2Na8ZMeta?.alias || [],
    redirect: indexKWJ6F2Na8ZMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/[id]/administration/index.vue").then(m => m.default || m)
  },
  {
    name: indexpJIf2s9ULVMeta?.name ?? "user-id-contact",
    path: indexpJIf2s9ULVMeta?.path ?? "contact",
    meta: indexpJIf2s9ULVMeta || {},
    alias: indexpJIf2s9ULVMeta?.alias || [],
    redirect: indexpJIf2s9ULVMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/[id]/contact/index.vue").then(m => m.default || m)
  },
  {
    name: indexz4HtAkrcUZMeta?.name ?? "user-id",
    path: indexz4HtAkrcUZMeta?.path ?? "",
    meta: indexz4HtAkrcUZMeta || {},
    alias: indexz4HtAkrcUZMeta?.alias || [],
    redirect: indexz4HtAkrcUZMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxQt6J8t9CrMeta?.name ?? "user-id-pharmacies-overview",
    path: indexxQt6J8t9CrMeta?.path ?? "pharmacies-overview",
    meta: indexxQt6J8t9CrMeta || {},
    alias: indexxQt6J8t9CrMeta?.alias || [],
    redirect: indexxQt6J8t9CrMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/[id]/pharmacies-overview/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indextJzC35YUO5Meta?.name ?? "user-activation-uid-timestamp-hash-login",
    path: indextJzC35YUO5Meta?.path ?? "/user/activation/:uid()/:timestamp()/:hash()/login",
    meta: indextJzC35YUO5Meta || {},
    alias: indextJzC35YUO5Meta?.alias || [],
    redirect: indextJzC35YUO5Meta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/activation/[uid]/[timestamp]/[hash]/login/index.vue").then(m => m.default || m)
  },
  {
    name: index8GptEvfL6qMeta?.name ?? "user-login",
    path: index8GptEvfL6qMeta?.path ?? "/user/login",
    meta: index8GptEvfL6qMeta || {},
    alias: index8GptEvfL6qMeta?.alias || [],
    redirect: index8GptEvfL6qMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexopIpOlRKgjMeta?.name ?? "user-password",
    path: indexopIpOlRKgjMeta?.path ?? "/user/password",
    meta: indexopIpOlRKgjMeta || {},
    alias: indexopIpOlRKgjMeta?.alias || [],
    redirect: indexopIpOlRKgjMeta?.redirect,
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/password/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stub59CDIxQOB7Meta?.name ?? undefined,
    path: component_45stub59CDIxQOB7Meta?.path ?? "/cart",
    meta: component_45stub59CDIxQOB7Meta || {},
    alias: component_45stub59CDIxQOB7Meta?.alias || [],
    redirect: component_45stub59CDIxQOB7Meta?.redirect,
    component: component_45stub59CDIxQOB7
  },
  {
    name: component_45stub59CDIxQOB7Meta?.name ?? undefined,
    path: component_45stub59CDIxQOB7Meta?.path ?? "/user/login",
    meta: component_45stub59CDIxQOB7Meta || {},
    alias: component_45stub59CDIxQOB7Meta?.alias || [],
    redirect: component_45stub59CDIxQOB7Meta?.redirect,
    component: component_45stub59CDIxQOB7
  }
]
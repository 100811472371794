import { useWindowSize } from '@vueuse/core'

export function useViewport() {
  const size = useWindowSize()
  const isMobileMenu = computed(() => {
    return size.width.value < 1024
  })

  return { isMobileMenu }
}

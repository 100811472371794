<template>
  <div class="w-full">
    <svg :viewBox="viewBox" class="w-full h-auto chart-pie">
      <g :transform="`translate(${offsetX / 2} 0)`">
        <circle
          :cx="centerX"
          :cy="centerY"
          r="200"
          class="stroke-gray-300 pointer-events-none"
          stroke-width="3"
          fill="transparent"
          stroke-dasharray="18 8"
        />
        <g v-for="(slice, index) in slices" :key="index" class="pie-slice">
          <line
            :x1="centerX"
            :y1="centerY"
            :x2="centerX + slice.textPos.x"
            :y2="centerY + slice.textPos.y"
            :stroke="slice.color"
            stroke-width="2"
          />
          <path
            :d="describeArc(slice.startAngle, slice.endAngle)"
            :fill="slice.color"
            :style="{
              transformOrigin: `${centerX}px ${centerY}px`,
            }"
          />
          <circle
            :cx="centerX + slice.textPos.x"
            :cy="centerY + slice.textPos.y"
            r="5"
            :fill="slice.color"
          />
          <foreignObject
            :x="slice.box.x"
            :y="slice.box.y"
            :width="slice.box.width"
            height="300"
            class="pointer-events-none"
            :style="{
              transform: `translateY(${slice.box.delta * 60}px)`,
            }"
          >
            <div
              class="w-full h-full flex items-end"
              :class="{
                'text-right justify-end': !slice.isRight,
              }"
            >
              <div class="">
                <h4
                  class="font-semibold text-6xl leading-[0.7]"
                  :style="{
                    color: slice.color,
                  }"
                >
                  {{ valuePrefix }}{{ slice.value }}{{ valueSuffix }}
                </h4>
                <p
                  class="uppercase font-medium text-base md:text-xs mt-5 hyphens-auto text-balance"
                >
                  {{ slice.label }}
                </p>
              </div>
            </div>
          </foreignObject>
        </g>
        <circle
          :cx="centerX"
          :cy="centerY"
          r="110"
          fill="white"
          class="pointer-events-none"
        />

        <g class="pointer-events-none">
          <text y="-0.1" font-size="0.2" text-anchor="middle">CHF</text>
          <text y="0.1" font-size="0.2" text-anchor="middle">5,2 Mrd.</text>
        </g>
      </g>

      <foreignObject
        v-if="axisLabels?.length"
        :x="centerX - 100"
        :y="centerY - 100"
        width="200"
        height="200"
        class="pointer-events-none"
      >
        <div class="w-full h-full flex items-center justify-center text-center">
          <div class="text-2xl">
            <div v-for="label in axisLabels">{{ label }}</div>
          </div>
        </div>
      </foreignObject>
    </svg>
  </div>
</template>

<script setup lang="ts">
import { getColorPalette, type ChartColorPalette } from '~/helpers/charts'

const props = defineProps<{
  table: string[][]
  colorPalette: ChartColorPalette
  rotation: number
  offsetX: number
  mutedCount: number
  isWide: boolean
  valuePrefix?: string
  valueSuffix?: string
  axisLabels?: string[]
}>()

const colors = computed(() => getColorPalette(props.colorPalette))

const mutedColors = computed(() => [
  '#cccccc',
  '#bbbbbb',
  '#aaaaaa',
  '#999999',
  '#888888',
])

interface Slice {
  startAngle: number
  endAngle: number
  color: string
  textPos: { x: number; y: number }
  value: string
  label: string
  isRight: boolean
  box: { x: number; y: number; width: number; delta: number }
}

const lerp = (x: number, y: number, a: number) => x * (1 - a) + y * a
const clamp = (a: number, min = 0, max = 1) => Math.min(max, Math.max(min, a))
const invlerp = (x: number, y: number, a: number) => clamp((a - x) / (y - x))
const range = (x1: number, y1: number, x2: number, y2: number, a: number) =>
  lerp(x2, y2, invlerp(x1, y1, a))

const totalValue = computed(() => {
  return props.table.reduce((sum, item) => sum + parseFloat(item[1]), 0)
})

function getBox(x: number, y: number, isRight: boolean) {
  const boxY = centerY.value + y - 300 + 8
  const delta = range(minY.value, maxY.value, 0, 1, centerY.value + y)
  if (isRight) {
    const boxX = centerX.value + x + 10
    return {
      x: boxX,
      y: boxY,
      width: width.value - boxX,
      delta,
    }
  }
  return {
    x: 0,
    y: boxY,
    width: centerX.value + x - 10,
    delta,
  }
}

const slices = computed((): Slice[] => {
  const angleOffset = props.rotation * Math.PI * 2
  let cumulativeValue = 0
  return props.table.map((item, index) => {
    const value = parseFloat(item[1])
    const label = item[0]
    const startAngle =
      (cumulativeValue / totalValue.value) * 2 * Math.PI -
      Math.PI / 2 +
      angleOffset

    cumulativeValue += value
    const endAngle =
      (cumulativeValue / totalValue.value) * 2 * Math.PI -
      Math.PI / 2 +
      angleOffset
    const midAngle = (startAngle + endAngle) / 2
    const textPos = polarToCartesian(midAngle, labelRadius.value)

    const isRight = textPos.x >= 0
    const box = getBox(textPos.x, textPos.y, isRight)

    return {
      startAngle,
      endAngle,
      color: getColor(index),
      textPos,
      box,
      value: item[1],
      label,
      isRight,
    }
  })
})

function getColor(index: number) {
  if (index >= props.table.length - props.mutedCount) {
    return mutedColors.value[
      (index - (props.table.length - props.mutedCount)) %
        mutedColors.value.length
    ]
  }
  return colors.value[index % colors.value.length]
}

const width = computed(() => {
  if (props.isWide) {
    return 1080
  }

  return 770
})

const height = computed(() => 600)
const radius = computed(() => 180)
const labelRadius = computed(() => radius.value + 40)

const centerX = computed(() => width.value / 2 - props.offsetX * 50)
const centerY = computed(() => height.value / 2)

const viewBox = computed(() => {
  return `0 0 ${width.value} ${height.value}`
})

const minY = computed(() => (height.value - labelRadius.value * 2) / 2)
const maxY = computed(() => height.value - minY.value)

const describeArc = (startAngle: number, endAngle: number) => {
  const start = polarToCartesian(startAngle, radius.value)
  const end = polarToCartesian(endAngle, radius.value)

  const largeArcFlag = endAngle - startAngle <= Math.PI ? '0' : '1'

  return [
    'M',
    start.x + centerX.value,
    start.y + centerY.value,
    'A',
    radius.value,
    radius.value,
    0,
    largeArcFlag,
    1,
    end.x + centerX.value,
    end.y + centerY.value,
    'L',
    centerX.value,
    centerY.value,
    'Z',
  ].join(' ')
}

const polarToCartesian = (angle: number, radius: number) => {
  return {
    x: radius * Math.cos(angle),
    y: radius * Math.sin(angle),
  }
}
</script>

<style lang="postcss">
.chart-pie {
  > g:hover {
    path:not(:hover) {
      @apply fill-gray-300;
      + line {
        @apply stroke-gray-300;
      }
      + line + circle {
        @apply fill-gray-300;
      }
      + line + circle + g text {
        @apply fill-gray-300;
      }
    }
  }
  path,
  line {
    @apply transition ease-in-out;
  }
  .pie-slice {
    &:hover path {
      transform: scale(1.15);
    }
  }
  path {
    stroke: white;
    stroke-width: 3px;
  }
}
</style>

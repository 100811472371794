<template>
  <div
    v-if="title || isEditing"
    :id="id"
    class="first:mt-0 md:mb-10 paragraph-section-title max-w-prose"
    :class="
      options.headingLevel === 'h2'
        ? 'mt-20 md:mt-30 lg:mt-40 xl:mt-60 mb-20 lg:mb-30'
        : 'mt-20 md:mt-20 lg:mt-30 mb-10'
    "
  >
    <component
      :is="options.headingLevel === 'h3' ? 'h3' : 'h2'"
      v-blokkli-editable:field_title
      :class="
        options.headingLevel === 'h3'
          ? 'text-lg md:text-lg font-medium'
          : 'text-xl md:text-2xl lg:text-3xl xl:text-5xl font-semibold'
      "
    >
      {{ title }}
    </component>
    <p
      v-if="subtitle"
      v-blokkli-editable:field_subtitle
      class="text-md md:text-lg lg:text-xl xl:text-2xl mt-2 md:mt-5"
    >
      {{ subtitle }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { slugify } from '~/helpers/slugify'

const { options, isEditing } = defineBlokkli({
  bundle: 'section_title',
  options: {
    headingLevel: {
      type: 'radios',
      default: 'h2',
      label: 'Heading',
      options: {
        h2: 'H2',
        h3: 'H3',
      },
    },
    showInNavigation: {
      type: 'checkbox',
      label: 'Link in der Navigation anzeigen',
      default: true,
    },
  },
  editor: {
    addBehaviour: 'no-form',
  },
})

const props = defineProps<{
  title: string
  subtitle?: string
  anchor?: string
}>()

const id = computed(() => {
  return slugify(props.anchor || props.title)
})
</script>

<style lang="postcss">
.paragraph-section-title + .content {
  @apply mt-0;
}
</style>

<template>
  <div
    class="bg-white my-30"
    :class="{
      'max-w-prose': options.width === 'narrow',
    }"
  >
    <ParagraphChartBar
      v-if="options.chartType === 'bar' && tableData.rows?.length"
      :table="tableData.rows"
      :distribution="options.barDistribution"
      :color-palette="options.colorPalette"
      :muted-count="mutedCount"
      :value-suffix="valueSuffix"
      :value-prefix="valuePrefix"
    />
    <ParagraphChartPie
      v-else-if="options.chartType === 'pie' && tableData.rows?.length"
      :table="tableData.rows"
      :color-palette="options.colorPalette"
      :rotation="options.rotationOffset"
      :offset-x="options.pieOffsetX"
      :is-wide="options.width === 'full'"
      :muted-count="mutedCount"
      :axis-labels="axisLabels"
      :value-suffix="valueSuffix"
      :value-prefix="valuePrefix"
    />
    <ParagraphChartLine
      v-else-if="options.chartType === 'line' && tableData.rows?.length"
      :table="tableData.rows"
      :color-palette="options.colorPalette"
      :muted-count="mutedCount"
      :is-wide="options.width === 'full'"
      :padding-top-multiplicator="options.linePaddingTop"
      :padding-bottom-multiplicator="options.linePaddingBottom"
      :axis-labels="axisLabels"
      :value-suffix="valueSuffix"
      :value-prefix="valuePrefix"
    />
    <ParagraphChartMatrix
      v-else-if="options.chartType === 'matrix' && tableData?.rows"
      :table="tableData.rows"
      :color-palette="options.colorPalette"
      :is-wide="options.width === 'full'"
      :footnotes="footnotes"
    />
    <ParagraphChartMap
      v-else-if="options.chartType === 'map' && tableData?.rows"
      :table="tableData.rows"
      :color-palette="options.colorPalette"
      :is-wide="options.width === 'full'"
      :footnotes="footnotes"
    />

    <div v-if="copyright" class="mt-15 text-xs uppercase text-gray-700">
      {{ copyright }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphChartFragment } from '#graphql-operations'

withDefaults(
  defineProps<{
    copyright?: string
    footnotes?: string[]
    tableData?: ParagraphChartFragment['tableData']
    axisLabels?: string[]
    valuePrefix?: string
    valueSuffix?: string
  }>(),
  {
    copyright: '',
    footnotes: () => [],
    axisLabels: () => [],
    tableData: () => {
      return {
        rows: [],
      }
    },
  },
)

const { options } = defineBlokkli({
  bundle: 'chart',
  options: {
    chartType: {
      type: 'radios',
      label: 'Diagrammtyp',
      default: 'bar',
      displayAs: 'icons',
      options: {
        bar: { label: 'Balken', icon: 'icon-blokkli-option-chart-bar' },
        pie: { label: 'Kuchen', icon: 'icon-blokkli-option-chart-pie' },
        line: { label: 'Linie', icon: 'icon-blokkli-option-chart-line' },
        matrix: { label: 'Matrix', icon: 'icon-blokkli-option-chart-matrix' },
        map: { label: 'Karte', icon: 'icon-blokkli-option-chart-map' },
      },
    },

    mutedCount: {
      type: 'text',
      label: 'Ausgrauen',
      default: '0',
      inputType: 'number',
    },

    width: {
      type: 'radios',
      label: 'Breite',
      default: 'narrow',
      options: {
        narrow: 'Schmal',
        full: 'Breit',
      },
    },

    pieOffsetX: {
      type: 'range',
      label: 'Offset X',
      default: 0,
      min: -2,
      max: 2,
      step: 0.05,
    },

    linePaddingBottom: {
      type: 'range',
      label: 'Padding (unten)',
      default: 0,
      min: 0,
      max: 1,
      step: 0.01,
    },

    linePaddingTop: {
      type: 'range',
      label: 'Padding (oben)',
      default: 0,
      min: 0,
      max: 1,
      step: 0.01,
    },

    rotationOffset: {
      type: 'range',
      label: 'Rotation',
      default: 0,
      min: 0,
      max: 1,
      step: 0.01,
    },

    colorPalette: {
      type: 'radios',
      label: 'Farbpalette',
      default: 'berry',
      displayAs: 'colors',
      options: {
        mixed: { label: 'Mixed', class: 'bg-chart-mixed' },
        berry: { label: 'Berry', class: 'bg-berry' },
        green: { label: 'Grün', class: 'bg-green' },
        blue: { label: 'Blau', class: 'bg-blue' },
      },
    },

    barDistribution: {
      type: 'radios',
      label: 'Verteilung',
      default: 'percentage',
      options: {
        percentage: 'Prozentual',
        largest: 'Grösster Wert',
      },
    },
  },

  editor: {
    determineVisibleOptions: (ctx) => {
      if (ctx.options.chartType === 'bar') {
        return [
          'chartType',
          'width',
          'mutedCount',
          'barDistribution',
          'colorPalette',
        ]
      } else if (ctx.options.chartType === 'pie') {
        return [
          'chartType',
          'width',
          'pieOffsetX',
          'rotationOffset',
          'colorPalette',
          'mutedCount',
        ]
      } else if (ctx.options.chartType === 'line') {
        return [
          'chartType',
          'width',
          'colorPalette',
          'mutedCount',
          'linePaddingTop',
          'linePaddingBottom',
        ]
      } else if (ctx.options.chartType === 'matrix') {
        return ['chartType', 'colorPalette', 'mutedCount', 'width']
      } else if (ctx.options.chartType === 'map') {
        return ['chartType', 'colorPalette', 'mutedCount', 'width']
      }

      return ['chartType']
    },
  },
})

const mutedCount = computed(() => {
  const v = parseInt(options.value.mutedCount)
  if (isNaN(v)) {
    return 0
  }

  return Math.max(0, v)
})
</script>

<style lang="postcss">
.bg-chart-mixed {
  background: conic-gradient(
    theme('colors.berry'),
    theme('colors.orange'),
    theme('colors.green'),
    theme('colors.blue'),
    theme('colors.cyan'),
    theme('colors.lime')
  );
}
</style>

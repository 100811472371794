<template>
  <div class="w-full">
    <table
      class="w-full table-fixed chart-matrix-table bg-gray-50 text-xs relative"
    >
      <tbody>
        <tr v-for="(row, rowIndex) in rows">
          <template v-for="(cell, cellIndex) in row">
            <th
              v-if="cellIndex === 0"
              class="font-medium p-2 bg-gray-50 py-[7px]"
            >
              {{ cell.value }}
            </th>
            <td
              v-else
              class="p-2 py-[7px] text-center align-middle group cursor-pointer"
              :style="{
                backgroundColor: cell.color,
              }"
            >
              <div class="grid md:flex gap-2 items-center justify-center">
                <ChartFootnoteSymbol
                  v-for="(symbol, i) in cell.symbols"
                  :key="rowIndex + '_' + cellIndex + '_' + i"
                  :symbol="symbol.symbol"
                  inverted
                />
              </div>
              <div
                class="absolute bottom-full left-0 w-full hidden group-hover:block pointer-events-none text-left bg-gray-100 text-base p-10"
                v-html="cell.label"
              />
            </td>
          </template>
        </tr>
      </tbody>
    </table>

    <ChartLegend :items="legendItems" :colors="colors" />

    <ChartFootnotes
      v-if="footnotes.length"
      :footnotes="footnotes"
      show-symbols
    />
  </div>
</template>

<script setup lang="ts">
import {
  getColorPalette,
  getSymbols,
  type ChartColorPalette,
} from '~/helpers/charts'
import { stripTags } from '~/helpers/string'

const props = defineProps<{
  table: string[][]
  colorPalette: ChartColorPalette
  isWide: boolean
  footnotes: string[]
}>()

const colors = computed(() => getColorPalette(props.colorPalette))

const legendItems = computed(() => props.table[0].slice(1))

function getColor(index: number) {
  return colors.value[index % colors.value.length]
}

const rows = computed(() =>
  props.table.slice(1).map((row) => {
    return row.map((value, index) => {
      const symbols = getSymbols(value).map((symbol) => {
        return {
          symbol,
          label: stripTags(props.footnotes[symbol - 1] || ''),
        }
      })
      const label = legendItems.value[index - 1] || ''
      return {
        value,
        color: symbols.length ? getColor(index - 1) : 'transparent',
        label: `<h4>${row[0]}</h4><p>${label}</p><p>${symbols.map((v) => v.label).join('<br>') || '&mdash;'}</p>`,
        symbols,
      }
    })
  }),
)
</script>

<style lang="postcss">
.chart-matrix-table {
  th,
  td {
    @apply border-3 border-collapse border-white;
    &:last-child {
      @apply border-r-0;
    }
    &:first-child {
      @apply border-l-0;
    }
  }
}
</style>

<template>
  <div class="mb-20 col-span-2">
    <div class="box bg-white">
      <div class="flex">
        <div class="flex-1">
          <div class="box-padding h-full flex flex-col pb-0">
            <SpriteSymbol
              name="email"
              class="text-green w-50 h-50 md:w-80 md:h-80"
            />
            <Divider class="mt-10 mb-20 text-green" />
            <h2
              v-blokkli-editable:field_title
              class="text-xl md:text-2xl lg:text-3xl mb-10"
            >
              {{ title }}
            </h2>
            <div
              v-if="description"
              v-blokkli-editable:field_description
              v-html="description"
            />

            <div class="mt-20 md:mt-auto">
              <div v-if="link" class="box-pull-down">
                <ButtonStyle v-slot="{ classes }">
                  <DrupalLink :to="link.uri?.path" :class="classes">
                    <SpriteSymbol name="arrow" class="h-10 w-20 mr-10" />
                    {{ link.title || $texts('email', 'E-Mail') }}
                  </DrupalLink>
                </ButtonStyle>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphContactBoxGenericFragment } from '#graphql-operations'
defineBlokkli({
  bundle: 'contact_box_generic',
})

defineProps<{
  title: ParagraphContactBoxGenericFragment['title']
  description: ParagraphContactBoxGenericFragment['description']
  link?: ParagraphContactBoxGenericFragment['link']
}>()
</script>

<template>
  <div class="bk-main-canvas">
    <ClientOnly>
      <Notifications />
      <div
        v-if="drupalUser.accessToolbar && !isEditing"
        class="bg-white border-b border-b-gray-100 hidden lg:block"
      >
        <VuepalAdminToolbar :key="language" />
        <div class="flex">
          <div
            class="py-10 xl:min-w-[1174px] mx-auto w-auto flex justify-between items-center"
          >
            <VuepalLocalTasks />
            <div>
              <button @click="toggleDebug">Text Keys anzeigen</button>
            </div>
          </div>
        </div>
      </div>
    </ClientOnly>

    <AppTop @header-visibility-changed="updateHeaderVisible" />

    <NuxtPageDependency>
      <Breadcrumb :links="breadcrumb" />
    </NuxtPageDependency>

    <div class="page-content">
      <NuxtPage />
    </div>

    <ScrollTopButton :visible="!headerVisible" class="mobile-only:hidden" />
    <NuxtPageDependency>
      <Transition name="app-bottom">
        <AppBottom v-show="!isReduced" :header-visible="headerVisible" />
      </Transition>
    </NuxtPageDependency>

    <ClientOnly>
      <CookieBanner />
      <TermsOfServiceOverlay v-if="showTermsOfServiceModal" />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { useDrupalUser } from './stores/drupalUser'

const runtimeConfig = useRuntimeConfig()
const route = useRoute()
const breadcrumb = useBreadcrumbLinks()
const drupalUser = useDrupalUser()
const language = useCurrentLanguage()
const site = useCurrentSite()

const { toggleDebug } = useEasyTexts()

useHead({
  htmlAttrs: {
    lang: language.value,
  },
  titleTemplate: (titleChunk) => {
    const suffix = runtimeConfig.public.titleSuffix || ''
    return titleChunk ? `${titleChunk} | ${suffix}` : suffix
  },
})

const isReduced = computed(() => {
  if (route.meta.isReduced) {
    if (typeof route.meta.isReduced === 'function') {
      return route.meta.isReduced(route)
    }
  }
  return !!route.meta.isReduced
})

const isEditing = computed(
  () =>
    !!(route.query.blokkliEditing || route.query.blokkliPreview) &&
    drupalUser.canUseParagraphsBuilder,
)

const headerVisible = ref(true)
const updateHeaderVisible = (isVisible: boolean) => {
  headerVisible.value = isVisible
}

const showTermsOfServiceModal = computed(
  () =>
    site.name === 'pharmasuisse' &&
    drupalUser.isLoggedIn &&
    !drupalUser.termsOfServiceAccepted &&
    route.query['no-tos-check'] !== 'true',
)
</script>

<style lang="postcss">
.page-content {
  min-height: calc(100vh - var(--layout-header-height) - 5rem);
}

@screen lg {
  .page-slide-enter-active,
  .page-slide-leave-active {
    transition: all 0.4s;
    position: absolute;
    width: 100%;
  }
  .page-slide-enter-from,
  .page-slide-leave-to {
    opacity: 0;
    transform: translateY(40%);
  }

  .page-fade-enter-active,
  .page-fade-leave-active {
    transition: all 0.3s;
    width: 100%;
  }
  .page-fade-enter-from,
  .page-fade-leave-to {
    opacity: 0;
  }

  .app-bottom-enter-active,
  .app-bottom-leave-active {
    transition: all 0.4s;
  }
  .app-bottom-enter-from,
  .app-bottom-leave-to {
    opacity: 0;
    transform: translateY(40%);
  }
}

.bk-body #nuxt-root .bk-main-canvas {
  @apply bg-gray-50;
}

.pb-paragraphs-container .pb-list-item {
  @apply col-span-full col-start-1;
}

.vuepal-admin-toolbar {
  z-index: 9999999999999999;
}
</style>

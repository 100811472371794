<template>
  <tr>
    <th v-blokkli-editable:field_title>{{ title }}</th>
    <td>
      <div
        v-blokkli-editable:paragraphs_text
        class="content mt-0 mb-0"
        v-html="text"
      />
    </td>
  </tr>
</template>

<script lang="ts" setup>
defineBlokkli({
  bundle: 'table_row',
})

defineProps<{
  title: string
  text: string
}>()
</script>

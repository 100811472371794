<template>
  <div
    class="mb-20"
    :class="
      options.showImage ? 'lg:col-span-2' : 'lg:col-span-1 lg:aspect-square'
    "
  >
    <BoxContact v-bind="personMapped" :hide-image="!options.showImage" />
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphContactBoxFragment } from '#graphql-operations'

defineOptions({
  name: 'ParagraphImage',
})

const { options } = defineBlokkli({
  bundle: 'contact_box',
  options: {
    showImage: {
      type: 'checkbox',
      label: 'Bild anzeigen',
      default: true,
    },
  },
})

const props = defineProps<{
  emailOverride?: ParagraphContactBoxFragment['emailOverride']
  phoneOverride?: ParagraphContactBoxFragment['phoneOverride']
  person: ParagraphContactBoxFragment['person']
}>()

// The mapped person. The paragraph can override the phone and/or email, so we
// construct a new object that merges these.
const personMapped = computed(() => {
  if (!props.person) {
    return {}
  }
  return {
    ...props.person,
    email: props.emailOverride || props.person?.email,
    phone: props.phoneOverride?.first?.parsed
      ? props.phoneOverride
      : props.person?.phone,
  }
})
</script>

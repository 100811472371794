<template>
  <div>
    <ParagraphChartBarDistribution
      v-if="isDistribution"
      :table="table"
      :distribution="distribution"
      :color-palette="colorPalette"
      :value-suffix="valueSuffix"
      :value-prefix="valuePrefix"
    />
    <ParagraphChartBarSingle
      v-else
      :table="table"
      :distribution="distribution"
      :color-palette="colorPalette"
      :muted-count="mutedCount"
    />
  </div>
</template>

<script setup lang="ts">
import type { ChartBarDistribution, ChartColorPalette } from '~/helpers/charts'

const props = defineProps<{
  table: string[][]
  distribution: ChartBarDistribution
  colorPalette: ChartColorPalette
  mutedCount: number
  valuePrefix?: string
  valueSuffix?: string
}>()

const isDistribution = computed(() => props.table[0]?.length > 2)
</script>

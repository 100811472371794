<template>
  <div v-if="title || isEditing" class="relative">
    <div class="">
      <div class="bg-gradient-to-r from-blue to-cyan lg:h-[370px]">
        <div
          class="relative z-50 p-20 lg:absolute lg:right-0 lg:top-0 lg:bottom-0 lg:w-[400px] xl:w-[450px] lg:py-20 lg:pr-40 lg:flex lg:items-center lg:text-right"
        >
          <BlokkliEditable v-slot="{ value }" name="field_title" :value="title">
            <SplitTitle
              v-slot="{ top, bottom }"
              :title="value"
              :bold-count="titleWords"
            >
              <h2
                v-blokkli-editable:field_title
                class="text-2xl md:text-3xl lg:text-4xl text-white hyphens-auto"
              >
                <span class="font-semibold">{{ top }}</span>
                <span>{{ bottom }}</span>
              </h2>
            </SplitTitle>
          </BlokkliEditable>
        </div>
        <FieldItemTypeImage
          v-if="image"
          v-bind="image.image"
          :image-style="imageStyle"
          class="relative z-40 lg:absolute lg:bottom-0 lg:left-0 lg:top-0"
        />
        <div class="absolute top-0 left-0 w-full h-full z-10">
          <Background class="relative w-full h-full" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Background from './Background.vue'
import type { ParagraphEditorialTitleFragment } from '#build/graphql-operations'

const { isEditing } = defineBlokkli({
  bundle: 'editorial_title',
})

withDefaults(
  defineProps<{
    title?: string
    image?: ParagraphEditorialTitleFragment['image']
    titleWords?: number
  }>(),
  {
    title: '',
    titleWords: 0,
  },
)

const imageStyle = defineImageStyle({
  type: 'pictures',
  pictures: {
    sm: {
      width: 480,
      height: 370,
    },
    md: {
      width: 770,
      height: 370,
    },
  },
})
</script>

<style lang="postcss"></style>

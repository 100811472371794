<template>
  <div
    class="fixed top-0 left-0 w-full h-full z-[99999999] bg-gray-900/95 flex items-center justify-center md:p-50"
  >
    <div
      class="bg-white shadow-xl max-w-reduced w-full max-h-full overflow-auto"
    >
      <div class="p-15 lg:p-25">
        <h2 class="text-lg md:text-2xl lg:text-3xl font-semibold mb-5">
          {{
            $texts(
              'termsOfServiceModal.title',
              'Nutzungsbedingungen akzeptieren',
            )
          }}
        </h2>

        <p class="lg:text-lg">
          {{
            $texts(
              'termsOfServiceModal.lead',
              'Sie müssen die Nutzungsbedingungen akzeptieren bevor Sie die Seite verwenden können.',
            )
          }}
        </p>
        <div class="mt-25" v-html="markup" />
      </div>

      <div
        class="p-15 lg:p-25 !pt-5 sticky bottom-0 bg-white z-50 border-t border-t-gray-300"
      >
        <InputsTermsOfService v-model="accepted" without-link />

        <div v-if="errors.length" class="mb-25 text-red">
          <div v-for="v in errors">{{ v }}</div>
        </div>

        <button
          class="button is-primary"
          :disabled="!accepted"
          @click.prevent="acceptTos"
          :class="{ 'is-loading': isLoading }"
        >
          {{ $texts('termsOfServiceModal.save', 'Speichern') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { TermsOfServiceOverlayParagraphFragment } from '#build/graphql-operations'
import { falsy } from '~/helpers/type'
import { useDrupalUser } from '~/stores/drupalUser'

await initFormKit()

const user = useDrupalUser()
const { $texts } = useNuxtApp()

const accepted = ref(false)
const isLoading = ref(false)
const errors = ref<string[]>([])

/**
 * Map possible paragraphs from the terms of service page to string.
 */
const mapParagraph = (
  v:
    | TermsOfServiceOverlayParagraphFragment
    | null
    | { paragraphs?: TermsOfServiceOverlayParagraphFragment[] },
): string | undefined => {
  if (!v) {
    return
  }

  if ('text' in v && v.text) {
    return `<div class="content mb-20">${v.text}</div>`
  } else if ('title' in v && v.title) {
    return `<h3 class="font-semibold text-lg mt-20 mb-10">${v.title}</h3>`
  } else if ('link' in v && v.link?.uri?.path) {
    return `<a target="_blank" class="button is-primary no-underline mr-10 mb-10" href="${v.link.uri.path}?no-tos-check=true">${v.link.title}</a>`
  } else if ('paragraphs' in v && v.paragraphs?.length) {
    return v.paragraphs.flatMap(mapParagraph).filter(falsy).join('')
  }
}

const { data: markup } = await useAsyncData(
  () =>
    useGraphqlQuery('termsOfServiceOverlay').then((v) => {
      return (v.data.globalConfig?.node?.paragraphs || [])
        .map(mapParagraph)
        .filter(falsy)
        .join('')
    }),
  {
    default: () => '',
  },
)

const acceptTos = async () => {
  if (isLoading.value) {
    return
  }
  isLoading.value = true

  try {
    const data = await useGraphqlMutation('userAcceptTos', {
      id: user.id,
    })
    if (data.data.pharmasuisseUpdateUser.errors?.length) {
      errors.value = data.data.pharmasuisseUpdateUser.errors
    } else {
      // Reload the page.
      window.location.href = window.location.href
      return
    }
  } catch (_e) {
    errors.value = [
      $texts('unexpectedError', 'Es ist ein unerwarteter Fehler passiert.'),
    ]
  }

  isLoading.value = false
}
</script>

<template>
  <div
    v-blokkli-droppable:field_image
    class="my-10 md:my-20 lg:my-30"
    :class="{ 'max-w-prose': options.aspectRatio !== 'wide' }"
  >
    <template v-if="image">
      <a
        v-if="options.enlarge === 'yes'"
        :href="image.mediaFileUrl?.path"
        class="relative"
        target="_blank"
        @click="onClick"
      >
        <MediaImage
          v-bind="image"
          :key="options.aspectRatio"
          :image-style="imageStyle"
          show-caption
        />
        <SpriteSymbol
          class="absolute top-10 right-10 w-60 h-50 bg-white rounded-[5px] fill-green-alt"
          name="image-enlarge"
        />
      </a>
      <MediaImage
        v-else
        v-bind="image"
        :key="options.aspectRatio"
        :image-style="imageStyle"
        show-caption
      />
    </template>
    <Teleport to="body">
      <TransitionFade>
        <Modal v-if="isModalOpen" @close="closeModal">
          <img :src="image?.mediaFileUrl?.path" />
        </Modal>
      </TransitionFade>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphImageFragment } from '#graphql-operations'

defineOptions({
  name: 'ParagraphImage',
})

const { options, uuid } = defineBlokkli({
  bundle: 'image',
  options: {
    aspectRatio: {
      type: 'radios',
      label: 'Bildverhältnis',
      options: {
        square: 'Quadratisch',
        original: 'Original',
        wide: 'Widescreen',
      },
      default: 'wide',
    },
    enlarge: {
      type: 'radios',
      label: 'Vergrößerung',
      displayAs: 'icons',
      options: {
        no: { label: 'Nein', icon: 'icon-blokkli-option-no' },
        yes: {
          label: 'Ja',
          icon: 'icon-blokkli-option-yes',
        },
      },
      default: 'no',
    },
  },
})

defineProps<{
  image?: ParagraphImageFragment['image']
}>()

const onClick = (event: MouseEvent) => {
  if (isMobileMenu.value) {
    return
  }
  event.preventDefault()
  openModal.value = uuid
}

const openModal = useReactiveQueryString('modal')

function closeModal() {
  openModal.value = ''
}

const { isMobileMenu } = useViewport()

const computedImageFormat = computed(() => {
  return options?.value.aspectRatio
})

const isModalOpen = computed(() => openModal.value === uuid)

/**
 * The responsive image style name to use.
 */
const imageStyle = computed(() => {
  if (computedImageFormat.value === 'square') {
    return imageStyleSquare
  } else if (computedImageFormat.value === 'original') {
    return imageStyleOriginal
  }
  return imageStyleWide
})

const imageStyleWide = defineImageStyle({
  type: 'sizes',
  aspectRatio: 1170 / 370,
  sizes: {
    md: 728,
    lg: 984,
    xl: 1170,
  },
})

const imageStyleSquare = defineImageStyle({
  type: 'sizes',
  aspectRatio: 1,
  sizes: {
    sm: 768,
    md: 768,
    lg: 646,
    xl: 770,
  },
})

const imageStyleOriginal = defineImageStyle({
  type: 'sizes',
  sizes: {
    sm: 768,
    md: 768,
    lg: 646,
    xl: 770,
  },
})
</script>

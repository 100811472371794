<template>
  <div v-if="structuredTable" class="my-20 max-w-prose">
    <StructuredTable
      v-bind="structuredTable"
      :mobile-style="options.mobileStyle"
      :has-stripes="parentType !== 'accordion_element'"
    />
  </div>
</template>

<script lang="ts" setup>
import { ParagraphTableMarkupFragment } from '#graphql-operations'

const { options, parentType } = defineBlokkli({
  bundle: 'table_markup',
  options: {
    mobileStyle: {
      type: 'radios',
      label: 'Mobile Darstellung',
      default: 'vertical',
      options: {
        vertical: 'Vertikal',
        horizontal: 'Horizontal',
      },
    },
  },
  editor: {
    addBehaviour: 'no-form',
  },
})

defineProps<{
  structuredTable: ParagraphTableMarkupFragment['structuredTable']
}>()
</script>

<template>
  <div
    v-if="text || isEditing"
    v-blokkli-editable:paragraphs_text
    class="content max-w-prose paragraph-text"
    :class="{ 'text-white': !!background }"
    v-html="text"
  />
</template>

<script lang="ts" setup>
// Provided by parent paragraphs.
// If any background is set we change the text color to white.
const background = inject<string>('background', '')

const { isEditing } = defineBlokkli({
  bundle: 'text',
  editor: {
    addBehaviour: 'editable:paragraphs_text',
  },
})

defineProps<{
  text?: string
}>()
</script>

<style lang="postcss">
.paragraph-text + .paragraph-text {
  @apply mt-20;
}
</style>

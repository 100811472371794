import { COLORS } from '../colors'

export type ChartColorPalette = 'berry' | 'green' | 'blue' | 'mixed'

export type ChartBarDistribution = 'percentage' | 'largest'

const BERRY = [COLORS.berry, COLORS['berry-light'], COLORS['berry-lighter']]

const ORANGE = [COLORS.orange, COLORS['orange-light']]

const GREEN = [COLORS.green, COLORS['green-light'], COLORS['green-lighter']]

const LIME = [COLORS.lime, COLORS['lime-light']]

const BLUE = [COLORS.blue, COLORS['blue-light'], COLORS['blue-lighter']]

const CYAN = [COLORS.cyan, COLORS['cyan-light'], COLORS['cyan-lighter']]

export const GRAY = [
  COLORS['gray-50'],
  COLORS['gray-100'],
  COLORS['gray-300'],
  COLORS['gray-500'],
  COLORS['gray-700'],
  COLORS['gray-900'],
]

const MIXED = [
  BERRY[0],
  ORANGE[0],
  LIME[0],
  GREEN[0],
  CYAN[0],
  BLUE[0],
  BERRY[1],
  ORANGE[1],
  LIME[1],
  GREEN[1],
  CYAN[1],
  BLUE[1],
]

export function getColorPalette(color: ChartColorPalette): string[] {
  if (color === 'berry') {
    return [...BERRY, ...ORANGE.reverse()]
  } else if (color === 'blue') {
    return [...BLUE, ...CYAN.reverse()]
  } else if (color === 'mixed') {
    return MIXED
  }

  return [...GREEN, ...LIME.reverse()]
}

export function getSymbols(value: string): number[] {
  const match = /{([^}]*)}/g.exec(value)
  return (
    match?.[1]
      .split(',')
      .map((v) => {
        return parseInt(v.trim())
      })
      .filter((v) => !isNaN(v)) || []
  )
}

<template>
  <div class="lg:my-10 xl:my-20 max-w-prose py-20">
    <table class="structured-table w-full has-mobile-style">
      <BlokkliField :list="paragraphs" tag="tbody" name="field_paragraphs" />
    </table>
  </div>
</template>

<script lang="ts" setup>
import { ParagraphTableFragment } from '#graphql-operations'

defineBlokkli({
  bundle: 'table',
})

defineProps<{
  paragraphs: ParagraphTableFragment['paragraphs']
}>()
</script>

<template>
  <div class="paragraph-product-teaser my-10 md:my-30">
    <CommerceProduct v-if="product" v-bind="product" :display-button="true" />
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphProductTeaserFragment } from '#graphql-operations'

defineOptions({
  name: 'ParagraphProductTeaser',
})

defineBlokkli({
  bundle: 'product_teaser',
})

defineProps<{
  product: ParagraphProductTeaserFragment['product']
}>()
</script>

<template>
  <div v-if="node || isEditing" class="paragraph-node-teaser col-span-1">
    <NodeTile v-if="node" v-bind="node" :color="color" />
    <div v-else-if="isEditing"></div>
  </div>
</template>

<script lang="ts" setup>
import { ParagraphNodeTeaserFragment, SiteColor } from '#graphql-operations'

const { options } = defineBlokkli({
  bundle: 'node_teaser',
  globalOptions: ['teaserColor'],
  editor: {
    previewWidth: 500,
  },
})

defineProps<{
  node: ParagraphNodeTeaserFragment['node']
}>()

const color = computed(() => {
  return options.value.teaserColor as SiteColor
})

const isEditing = inject('isEditing', false)
</script>

<style lang="postcss">
:not(.paragraph-node-teaser) + .paragraph-node-teaser {
  @apply col-start-1;
}
</style>

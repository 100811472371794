<template>
  <div>
    <div class="relative pb-40">
      <div class="w-full text-xs grid gap-25 md:gap-10 relative z-10">
        <div
          v-for="item in items"
          class="flex flex-col md:flex-row gap-5 md:gap-10"
        >
          <h4
            class="md:bg-gray-100 uppercase font-medium md:flex md:items-center md:px-10 mobile-only:w-auto"
            :style="{
              width: width + 'px',
            }"
          >
            {{ item.title }}
          </h4>
          <div class="flex flex-1">
            <div
              v-for="(cell, i) in item.data"
              class="text-white font-medium h-50 flex items-center justify-end"
              :style="{
                flex: cell,
                backgroundColor: colors[i],
              }"
            >
              <div class="px-10">
                {{ valuePrefix }}{{ cell }}{{ valueSuffix }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="absolute top-0 left-0 w-full h-full hidden md:block">
        <div class="flex justify-between h-full">
          <div
            v-for="(x, index) in xAxis"
            class="relative flex-1 h-full text-right border-r border-dashed border-r-gray-500 flex items-end text-xs mobile-only:!flex-1"
            :style="
              index === 0
                ? {
                    flex: '0 0 ' + (width + 10 + 'px'),
                  }
                : {}
            "
          >
            <div class="pr-5 leading-[0.75] flex-1 text-right pt-15 border-t">
              {{ x }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <ChartLegend :items="dataPoints" :colors="colors" />
  </div>
</template>

<script setup lang="ts">
import {
  getColorPalette,
  type ChartBarDistribution,
  type ChartColorPalette,
} from '~/helpers/charts'

const props = defineProps<{
  table: string[][]
  distribution: ChartBarDistribution
  colorPalette: ChartColorPalette
  valuePrefix?: string
  valueSuffix?: string
}>()

const width = ref(260)

function filterEmpty(v: any) {
  return v !== undefined && v !== null && typeof v === 'string' && v.length
}

const dataPoints = computed(() => {
  return props.table.map((v) => v[0]).filter(filterEmpty)
})

const labels = computed(() => props.table[0].map((v) => v).filter(filterEmpty))

const items = computed(() => {
  return labels.value.map((_v, index) => {
    const data = props.table.slice(1).map((values) => values[index + 1])
    const values = data.map((v) => {
      if (typeof v === 'string') {
        const num = parseFloat(v)
        if (isNaN(num)) {
          return 0
        }

        return num
      }

      return 0
    })
    const total = values.reduce((acc, v) => {
      return acc + v
    }, 0)
    return {
      title: labels.value[index],
      data,
      total,
    }
  })
})

const xAxis = computed(() => {
  return ['0%', '25%', '50%', '75%', '100%']
})

const colors = computed(() => getColorPalette(props.colorPalette))
</script>
